import { useState } from 'react';
import {Container,Form,Row,Col,Button} from 'react-bootstrap';
import axios from "axios";
import './Login.css';
import apilist from '../apilist';

function Login(){
    const getUser = localStorage.getItem("user_id");
    const getUserdata = localStorage.getItem("user_data");
    //console.log(apilist.apiurl);
    console.log("get user id "+getUser);
    const[values , setValues] = useState( {
        phone: "",
        otp:""
      });
    const [submitButton, setSubmitButton] = useState('sendotp');
    const [isClickedBtn, setClickedBtn] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isPhoneDisabled, setPhoneDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const handelSubmitSend = () => {
        setClickedBtn('send');
    };
    const handelSubmitVerify = () => {
        setClickedBtn('verify');
    };
    const handleSubmit = (event) => {
        console.log(isClickedBtn);
        const form = event.currentTarget;
        
        //setButtonDisabled(true);
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          document.querySelector('input:invalid').focus();
          //setButtonDisabled(false);
        }else{
          event.preventDefault();
          console.log(values);
          setPhoneDisabled(true);
          setSubmitButton('verify');
          if(isClickedBtn === 'send'){
            console.log("sending");
            axios.post(apilist.apiurl+'/api/login', values)
                    .then(res => {
                      console.log(res.data);
                      if(res.data.status === true){
                        alert(res.data.message);
                        //window.location.reload();
                      }else{
                        alert(res.data.message);
                      }
                      console.log(res);
                      }).catch(err=>{
                        alert("Error!");
                        console.log("err",err);
                        setButtonDisabled(false);
                      });  
          }else if(isClickedBtn === 'verify'){
            console.log("verify");
            axios.post(apilist.apiurl+'/api/login/verify', values)
                    .then(res => {
                      console.log(res.data);
                      if(res.data.status === true){
                        localStorage.setItem('user_id', res.data.data.id);
                        localStorage.setItem('user_data', JSON.stringify(res.data.data));
                        window.location.reload();
                      }else{
                        alert(res.data.message);
                      }
                      console.log(res);
                      }).catch(err=>{
                        alert("Error!");
                        console.log("err",err);
                        setButtonDisabled(false);
                      });  
          }
                   
        }
    
        setValidated(true);
      };
      const handleChangeOne = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        // console.log(values);
    };
      const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        e.target.value = onlyDigits; 
        handleChangeOne(e);
      };
return (
    <>
    <div className="logincontainer py-3 py-md-5">
        <Container>
            <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                <div className="row">
                    <div className="col-12">
                    <div className="text-center mb-5">
                        <h2>Login</h2>
                    </div>
                    </div>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className='mb-2' controlId="validationCustom02">
                            <Form.Label>Mobile <div>फोन नंo *</div></Form.Label>
                            <Form.Control
                            value={values.phone}
                            name="phone"
                            required
                            type="tel"
                            maxLength="10"
                            placeholder=""
                            disabled={isPhoneDisabled}
                            onChange={handleChangeOne}
                            />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={12}>
                            <Form.Group className='mb-2' controlId="validationCustom02">
                            <Form.Label>OTP <div></div></Form.Label>
                            <Form.Control
                            value={values.otp}
                            name="otp"                            
                            type="text"
                            placeholder=""
                            onChange={handleChangeOne}
                            />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"right"}}>
                            {(submitButton === 'sendotp') ? <Button className='m-2 subbtn' type="submit" onClick={handelSubmitSend}  style={{backgroundColor:'#f76030',borderColor:'#f76030', fontSize:'20px'}}>Send OTP</Button> : <Button className='m-2 verbtn' type="submit" onClick={handelSubmitVerify} style={{backgroundColor:'#f76030',borderColor:'#f76030', fontSize:'20px'}}>Verify OTP</Button>}
                            
                            
                        </Col>
                    </Row>
                </Form>
               
                </div>
            </div>
            </div>
        </Container>
        </div>
    </>
);
}
export default Login;