import { useEffect, useState } from "react";
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Form from './Form/SurveyForm';
//import apilist from './apilist';
//import SurveyForm from './Form/SurveyForm';
import Header from './component/Header';
import Footer from './component/Footer';
import Login from './users/Login';
import Logout from './users/Logout';
import Profile from './users/profile/Profile';
import isAuth from "./isAuth";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(()=>{
    if(localStorage.getItem("user_id")){
      setIsAuthenticated(true);
    }else{
      setIsAuthenticated(false);
    }
  },[]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Form/>} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/user/profile" replace />:<Login/>} />
        {/* <Route path="/login" element={<Login/>} /> */}
        {/* <Route element={<isAuth/>}> */}
          
        {/* </Route>         */}
        
        {/* <Route path="*" element={<Navigate to='/' />} /> */}
      </Routes>
      {(isAuthenticated) ? (
        <Routes>
          <Route path="/user/profile" element={<Profile/>} />
          <Route path="/logout" element={<Logout/>} />
        </Routes>
        ):''}
      {/* <Form/> */}
      <Footer />
    </div>
  );
}

export default App;
