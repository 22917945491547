import {Container,Navbar} from 'react-bootstrap';
import './header.css';
import logo from '../logo.png';
function Header(){
return (
    <>
    <Navbar collapseOnSelect expand="lg" className="" style={{backgroundColor:'#f60000',borderBottom:'5px solid #fff703'}}>
      <Container className="d-flex justify-content-md-center align-items-center">
        <Navbar.Brand href="/" style={{color:'#fff',display:'flex'}}><img src={logo} style={{width:'50px',height:'100%'}}></img><div className=" justify-content-md-center align-items-center logotext" style={{whiteSpace:"break-spaces"}}>Shri Digamber Jain Bhagwan ParshavNath Prabhavana Samiti <br /><div className='d-flex justify-content-md-center align-items-center' >(Sector-50 Noida 201301)</div></div></Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
    </>
)
}
export default Header;