import { useEffect, useState } from 'react';
import {Container,Table,Form,Row,Col,Button} from 'react-bootstrap';
import axios from "axios";
import apilist from '../../apilist';

function Profile(){
    const getUser = localStorage.getItem("user_id");
    const[userData, setUserData] = useState({
        name:"Ravi",
        phone:"",
        email:"",
        gender:"",
        dob:"",
        maritalstatus:"",
        address:"",
        education:"",
        bloodgroup:"",
        occupationtype:"",
        occupation:"",
        userpic:"",
        educationName:"",
        maritalName:"",
        occupationName:"",
        familydata:[]
    });
    axios({
        url: apilist.apiurl+'/api/vivran/'+getUser,
        method: 'get',
        headers: {
            'x-api-key': apilist.authkey,
            'Content-Type': 'application/json'
        }
     })
     .then(response => {
        setUserData(response.data.data);
        //console.log(response)
        //console.log(userData);
     }) 
     .catch(err => {
        console.log(err);
     });  

    return(
        <>
        <Container>
            <Row className="mb-3" style={{marginTop:'40px'}}>
                <Col className='formtitle '>
                    <h2>आप का विवरण </h2>
                </Col>
            </Row>
            <Row className="pageinnerBox" style={{paddingTop:'30px'}}>
                <Col>
                    <Row>
                        <Col xs={{span:12,order:2}} md={{span:8,order:1}} >
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <td className='udplabel col-sm-6 col-md-4'>                                    
                                            Name (Head of the family)<div>मुखिया का नाम *</div>                                    
                                        </td>
                                        <td className='udpval '>
                                            {userData.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel col-sm-6 col-md-4'>                                    
                                        Mobile <div>फोन नंo *</div>                   
                                        </td>
                                        <td className='udpval'>
                                            {userData.phone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Email <div>ईमेल *</div>    
                                        </td>
                                        <td className='udpval'>
                                            {userData.email}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Gender <div>लिंग *</div>         
                                        </td>
                                        <td className='udpval'>
                                            {userData.gender}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                        Date of Birth <div>जन्म तिथि *</div>    
                                        </td>
                                        <td className='udpval'>
                                            {userData.dob}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                        Blood Group <div>ब्लड ग्रुप</div>            
                                        </td>
                                        <td className='udpval'>
                                            {userData.bloodgroup}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Address <div>पता *</div>             
                                        </td>
                                        <td className='udpval'>
                                            {userData.address} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Marital Status <div>वैवाहिक स्थिति *</div>             
                                        </td>
                                        <td className='udpval'>
                                            {userData.maritalName} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Education <div>शिक्षा *</div>           
                                        </td>
                                        <td className='udpval'>
                                            {userData.educationName} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='udplabel'>                                    
                                            Occupation <div>पेशा *</div>           
                                        </td>
                                        <td className='udpval'>
                                            {userData.occupationtype} : {userData.occupationName} 
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={{span:12,order:1}} md={{span:4,order:2}} >
                            <div className={"row justify-content-center mt-1"}>
                            <div className='userpicblock' style={passportDivStyle}>
                                {userData.userpic &&  <img src={userData.userpic} alt="Passport" style={passportPreviewStyle} />}
                                <input type="file" class="form-control" required accept="image/*"  style={uploadInputStyle} />
                            </div>
                            <div className={"text-center"}>Photo<div>फोटो *</div></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><h4 className='m-2'>Family Details <div>परिवार का विवरण</div></h4></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                            <th>#</th>
                                            <th>Name<div>नाम</div></th>  
                                            <th>Relationship <div>मुखिया से संबंध</div></th> 
                                            <th>Date of Birth<div>जन्म तिथि</div></th>  
                                            <th>Education<div>शिक्षा</div></th> 
                                            <th>Occupation <div>पेशा</div></th>
                                            <th>Mobile<div>फोन नंo</div></th>  
                                            <th>Blood Group<div>ब्लड ग्रुप</div></th>  
                                            <th>Photo<div>फोटो *</div></th>                       
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {userData.familydata.map((row, index) => (
                                            <tr>
                                                <td className=''>
                                                    {index+1}    
                                                </td>
                                                <td className=''>                                    
                                                       {row.fname}                             
                                                </td>
                                                <td className=''>
                                                    {row.relation}
                                                </td>
                                                <td className=''>
                                                    {row.dob}
                                                </td>
                                                <td className=''>
                                                    {row.education}
                                                </td>
                                                <td className=''>
                                                    {row.occupation}
                                                </td>
                                                <td className=''>
                                                    {row.contact}
                                                </td>
                                                <td className=''>
                                                    {row.bloodGroup}
                                                </td>
                                                
                                                <td className=''>
                                                    
                                                    <div className={"row justify-content-center mt-1"}>
                                                        <div className='userpicblock' style={familyPicStyle}>
                                                        {<img src={(row.familypic) ? row.familypic : 'assets/img/upload-icon.png'}  alt="Passport" style={passportPreviewStyle} />}
                                                        <input type="file" class="form-control" accept="image/*"  style={uploadInputStyle} />                          
                                                        </div>                                                        
                                                    </div>
                                                </td>   
                                                                                            
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                
            </Row>
            
        </Container>            
        </>
    );

    
}
const passportDivStyle = {
    width: '150px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    padding:'0'
  };
const familyPicStyle = {
    width: '60px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    padding:'0'
  };
  
  const passportPreviewStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
  };
  
  const uploadInputStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: '0',
    cursor: 'pointer',
  };
export default Profile;
