import { useEffect, useState } from 'react';
import axios from "axios";
import {Container,Navbar, Card, Nav,NavDropdown} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import apilist from '../apilist';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUpload } from '@fortawesome/fontawesome-free-solid';
import './SurveyForm.css'
// import PassportPhotoUploader from '../PhotoUpload/PassportPhotoUploader';



function SurveyForm() {
  const[values , setValues] = useState( {
    name: "",
    contact: "",
    email: "",
    gender: "male",
    bloodgroup:"A+",
    dob: "",
    address: "",
    education: "",
    // designation: "",
    marital:"",
    occupationtype:"business",
    occupation:"",
    image:"",
    //business: "",
    familyDetails:""
  } );
  const [isOccuTypeB, setOccuTypeB] = useState(false);
  const [isOccuTypeS, setOccuTypeS] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [validated, setValidated] = useState(false);

  const [tableData, setTableData] = useState([
    { name: '', Relation: '', Familyocctype:'business', Occupation:'', DOB: '', Education: '', Contact: '', BloodGroup: '', familypic: '' }
  ]);
 
  const [previewSrc, setPreviewSrc] = useState("assets/img/upload-icon.png");
  const [familypreviewSrc, setFamilyPreviewSrc] = useState([]);
  const [base64String, setBase64String] = useState('');
  const [number, setNumber] = useState("");
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewSrc(e.target.result);        
      };
      reader.readAsDataURL(file);
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const base64 = btoa(e.target.result);
      setBase64String(base64);
    };

   reader.readAsBinaryString(file);
  };
  useEffect(()=>{
    
  });
  const handleFamilyPic = (event, index) => {
    const familypicData = [...familypreviewSrc];
    //console.log();
    //familypicData[index] = event.target.value;
    const file = event.target.files[0];
    var fileprev = "";
    const newData = [...tableData];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
       familypicData[index] = e.target.result
       setFamilyPreviewSrc(familypicData);  
      };
      reader.readAsDataURL(file);
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = btoa(e.target.result);
      newData[index]['familypic'] = base64;
      setTableData(newData);
    };   
   reader.readAsBinaryString(file);
  };
  

  // Function to handle file upload
  // const handleFileChange = (e) => {
    
  // };

  const handleChangeOne = (e) => {
    const { name, value } = e.target;
    setValues({
        ...values,
        [name]: value
    });
    if(e.target.name === 'occupationtype'){
      setOccuTypeB(false);
      setOccuTypeS(false);
     // console.log(e.target.value);
     if(e.target.value == 'business'){
      setOccuTypeB(true);
     }else if(e.target.value == 'service'){
      setOccuTypeS(true);
     }
      
    }
    // console.log(values);
};

  // Function to handle changes in input fields
  const handleChange = (index, key, value) => {
    const newData = [...tableData];
    //console.log(value);
   
    newData[index][key] = value;
    if(key == 'Familyocctype'){
      if(value == 'business'){
        console.log('business');
      }else if(value == 'service'){
        console.log('service');
      }
    }
    setTableData(newData);
  };

  // Function to add a new row to the table
  const addRow = () => {
    setTableData([...tableData, { name: '', Relation: '', Familyocctype:'business', Occupation:'', DOB: '', Education: '', Contact: '', BloodGroup: '', familypic:'' }]);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setButtonDisabled(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      document.querySelector('input:invalid').focus();
      setButtonDisabled(false);
    }else{
      event.preventDefault();
      //let btnsub = document.getElementsByClassName('subbtn');
      //btnsub.disabled = true;
      values.familyDetails=tableData;
      values.image=base64String;
      console.log(values);
      //axios.post(`https://jaintemplenoida50.com/server/index.php/api/vivran`, values)
    //   axios({
    //     url: apilist.apiurl+'/api/vivran/',
    //     method: 'post',
    //     headers: {
    //         'x-api-key': apilist.authkey,
    //         'Content-Type': 'application/json'
    //     }
    //  })
     axios.post(apilist.apiurl+'/api/vivran', values, {headers: {
        'x-api-key': apilist.authkey,
        'Content-Type': 'application/json'
      }}).then(res => {
                  console.log(res.data.status);
                  if(res.data.status === true){
                    alert(res.data.message);
                    window.location.reload();
                  }else{
                    alert(res.data.message);
                  }
                  console.log(res);
                  setButtonDisabled(false);
                  }).catch(err=>{
                    alert("Error!");
                    console.log("err",err);
                    setButtonDisabled(false);
                  });      
    }

    setValidated(true);
  };
  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    e.target.value = onlyDigits; 
    handleChangeOne(e);
  };
 const checkFamilyInput = (e,index,key) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    e.target.value = onlyDigits; 
    handleChange(index, key, e.target.value);
 };
  return (

    <>    
    <Container>
      <Row className="mb-3" style={{marginTop:'40px'}}>
      <Col className='formtitle '>
          <h2>जैन समाज नौएडा डायरेक्ट्री हेतु विवरण </h2>
      </Col>
      </Row>
      <div className="vivranform">

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row style={{paddingTop:'30px'}}>
          <Col xs={{span:12,order:2}} md={{span:8,order:1}} > 
            <Row  className="">
              <Col>
                <Form.Group  className='mb-2 mt-4' as={Col} md="12" controlId="validationCustom01">
                  <Form.Label className='label' >Name (Head of the family)<div>मुखिया का नाम *</div></Form.Label>
                  <Form.Control 
                    value={values.name} onChange={handleChangeOne}
                    name="name"
                    required
                    type="text"
                    placeholder="Name"
                  />
                  {/* <Form.Control.Feedback type='invalid'>skd</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className='mb-2' controlId="validationCustom02">
                <Form.Label>Mobile <div>फोन नंo *</div></Form.Label>
                <Form.Control
                  value={values.contact} 
                  name="contact"
                  required
                  type="tel"
                  maxLength="10"
                  placeholder=""
                  onChange={(e) => checkInput(e)}
                />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
              <Form.Group className='mb-2' controlId="validationCustom02">
                  <Form.Label >Email <div>ईमेल *</div></Form.Label>
                  <Form.Control
                  name="email"
                  value={values.email} onChange={handleChangeOne}
                    required
                    type="email"
                    placeholder="Email"
                  />                  
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
              <Form.Group className='' as={Col} md="12" controlId="validationCustom02">
                  <Form.Label >Gender <div>लिंग *</div></Form.Label><br/>
                  {/* <Form.Control
                    required
                    type="text"
                    placeholder="Gender"
                  /> */}
                  <Row>
                    <Col md={12}>
                      <div className='d-flex justify-content-between'>
                      <div key={`default-radio`} className="mb-3">
                      <Row>
                        <Col>
                          <Form.Check // prettier-ignore
                          inline
                          name="gender"
                          type="radio"
                          id="gender-male"
                          label="Male"
                          value = "male"
                          onChange={handleChangeOne}
                          required
                          defaultChecked
                          defaultValue="male" 
                                                            
                        />
                          <Form.Check // prettier-ignore
                          inline
                          name="gender"
                          type="radio"
                          id="gender-female"
                          label="Female"
                          value = "female"
                          onChange={handleChangeOne}
                          required
                        />
                          <Form.Check // prettier-ignore
                          inline
                          name="gender"
                          type="radio"
                          id="gender-other"
                          label="Other"
                          value = "other"
                          onChange={handleChangeOne}
                          required
                        />
                        </Col>
                      </Row>
                      
                      
                      
                      </div>
                      
                      </div>
                    </Col>
                  </Row>
                  
                  {/* <Form.Control.Feedback type='invalid'>skd</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}></Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
              <Form.Group className='mb-2' style={{ marginRight: "10px" }} as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Date of Birth <div>जन्म तिथि *</div></Form.Label>
                <Form.Control
                name="dob"
                  value={values.dob} onChange={handleChangeOne}
                  required
                  type="date"
                  placeholder=""
                />
                {/* <Form.Control.Feedback type='invalid'>skd</Form.Control.Feedback> */}
              </Form.Group>
              </Col>
              <Col xs={12} md={6}>
              <Form.Group className='mb-2' style={{ marginRight: "10px" }} as={Col} md="12">
                <Form.Label>Blood Group <div>ब्लड ग्रुप</div></Form.Label>
                {/* <Form.Control
                name="bloodgroup"
                  value={values.bloodgroup} onChange={handleChangeOne}
                  type="text"
                  placeholder=""
                /> */}
                <Form.Select name="bloodgroup" onChange={handleChangeOne} aria-label="Default select example" >
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </Form.Select>
                {/* <Form.Control.Feedback type='invalid'>skd</Form.Control.Feedback> */}
              </Form.Group>
              </Col>
            </Row>    
            
            <Row>
              <Col>
                <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Marital Status <div>वैवाहिक स्थिति *</div></Form.Label>
                <Form.Select name="marital" onChange={handleChangeOne} required aria-label="Default select example">
                  <option value="">--Select--</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="widowed">Widowed</option>
                  <option value="divorced">Divorced</option>
                  <option value="separated">Separated</option>
                  <option value="others">Others</option>
                </Form.Select>
              </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Education <div>शिक्षा *</div></Form.Label>
                <Form.Select name="education" onChange={handleChangeOne} required aria-label="Default select example">
                  <option value="">--Select--</option>
                  <option value="high_school">High School</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="under_gradiate">Under Graduate</option>
                  <option value="post_graduate">Post Graduate</option>
                  <option value="other">Other</option>
                </Form.Select>
              </Form.Group>
              </Col>
            </Row>
            <Row>
              
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Occupation <div>पेशा *</div></Form.Label>
                <Row>
                    <Col>
                      <Form.Check // prettier-ignore
                      inline
                      name="occupationtype"
                      type="radio"
                      id="occtype-business"
                      label="Business"
                      value = "business"
                      onChange={handleChangeOne}
                      required                                                        
                      />
                      <Form.Check // prettier-ignore
                      inline
                      name="occupationtype"
                      type="radio"
                      id="occtype-service"
                      label="Service"
                      value = "service"
                      onChange={handleChangeOne}
                      required
                      />                          
                    </Col>
                  </Row>
                     
                      
              </Form.Group>
             
              </Col>
              <Col>
                <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
                
                {
                  (isOccuTypeB)
                  ?
                  <Form.Label>Business Information <div>व्यापार जानकारी *</div></Form.Label>
                  :
                  ''
                }
                {
                  (isOccuTypeS)
                ?
                <Form.Label>Service Information <div>सेवा जानकारी *</div></Form.Label>
                :
                ''
                }
                {
                  (isOccuTypeB) 
                  ?                        
                  <Form.Control
                  name="occupation"
                    onChange={handleChangeOne}
                    required
                    type="text"
                    placeholder="About Business"
                  />
                  :                        
                  ''
                }
                {
                  (isOccuTypeS) 
                  ?
                  <Form.Select name="occupation" onChange={handleChangeOne} required aria-label="Default select example">
                  <option value="">--Select Service--</option>                        
                  <option value="architect">Architect</option>
                  <option value="ca">CA</option>
                  <option value="doctor">Doctor</option>
                  <option value="engineer">Engineer</option>
                  <option value="hr">HR</option>
                  <option value="it">IT</option>
                  <option value="legal">Legal</option>
                  
                  <option value="other">Other</option>
                  </Form.Select>
                  :                        
                  ''
                }
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Group className='mb-2' style={{ marginRight: "10px" }} as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Address <div>पता *</div></Form.Label>
                <Form.Control
                name='address'
                  value={values.address} onChange={handleChangeOne}
                  required
                  as="textarea"
                  placeholder="Address"
                />                
                </Form.Group>
              </Col>
            </Row>
            
            {/* <Row>
              <Col>
                <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Designation <div>पद का नाम *</div></Form.Label>
                <Form.Select name="designation" onChange={handleChangeOne} required aria-label="Default select example">
                  <option value="">--Select--</option>
                  <option value="cl">C-Level (e.g. CEO, CFO), Owner, Partner, President </option>
                  <option value="vp">Vice President (EVP, SVP, AVP, VP)</option>
                  <option value="d">Director (Group Director, Sr. Director, Director) </option>
                  <option value="m">Manager (Group Manager, Sr. Manager, Manager, Program Manager) </option>
                  <option value="a">Analyst</option>
                  <option value="aa">Assistant or Associate</option>
                  <option value="ad">Administrative (Clerical or Support Staff)</option>
                  <option value="c">Consultant</option>
                  <option value="i">Intern</option>
                  <option value="v">Volunteer</option>
                  <option value="hw">House Wife</option>
                  <option value="nota">None of the above</option>
                  <option value="o">Other</option>                  
                </Form.Select>
              </Form.Group>
              </Col>
            </Row> */}
            {/* <Row>
              <Col>
              <Form.Group className='mb-2' as={Col} md="12" controlId="validationCustom02">
              <Form.Label>Business Address <div>व्यावसायिक का पता *</div></Form.Label>
              <Form.Control
              name="business"
              value={values.business} onChange={handleChangeOne}
                required
                type="text"
                placeholder="Business Address"
              />
              
            </Form.Group>
              </Col>
            </Row> */}
            
                {/* left form */}


          </Col>
          <Col xs={{span:12,order:1}} md={{span:4,order:2}} >
            <div className={"row justify-content-center mt-1"}>
            <div className='userpicblock' style={passportDivStyle}>
                {/* {(previewSrc) ? <FontAwesomeIcon icon={faUpload} /> : <img src={previewSrc} alt="Passport" style={passportPreviewStyle} />} */}
                {previewSrc &&  <img src={previewSrc} alt="Passport" style={passportPreviewStyle} />}
                <input type="file" class="form-control" required accept="image/*" onChange={handleFileInputChange}  style={uploadInputStyle} />
            </div>
              <div className={"text-center"}>Upload Photo<div>फोटो *</div></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            
            
            
            
            
            
          </Col>
        </Row>
        <Row style={{marginTop:'34px',overflowX: "auto"}}>
        <Col>
          <h4 className='m-2'>Family Details <div>परिवार का विवरण</div></h4>
          </Col>
        </Row>

        <Row style={{marginTop:'34px',overflowX: "auto"}}>
          <Col xs={12} md={12}>
          {tableData.map((row, index) => (
            <Card style={{marginBottom:'20px', backgroundColor:'#fffff7',boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}>
            <Card.Body>
            <Card.Title>सदस्य - {index+1}</Card.Title>
            <Row>
                <Col xs={12} md={9}>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className='mb-2' >
                        <Form.Label>Name<div>नाम</div></Form.Label>
                        <Form.Control
                          type="text"
                          value={row.name}
                          onChange={(e) => handleChange(index, 'name', e.target.value)}  
                          placeholder='Name'
                        />                  
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                    <Form.Group className='mb-2' >
                      <Form.Label>Relationship <div>मुखिया से संबंध</div></Form.Label>
                      <Form.Select  onChange={(e) => handleChange(index, 'Relation', e.target.value)} aria-label="Default select example">
                      <option value="">--Select--</option>
                      {/* <option value="grandfather">Grandfather</option>
                      <option value="grandmother">Grandmother</option>     */}
                      <option value="father">Father</option>
                      <option value="mother">Mother</option>
                      <option value="husband">Husband</option>
                      <option value="wife">Wife</option>
                      <option value="brother">Brother</option>
                      <option value="sister">Sister</option>  
                      <option value="son">Son</option>
                      <option value="daughter">Daughter</option>
                      {/* <option value="grandson">Grandson</option>  */}
                      {/* <option value="uncle">Uncle</option> 
                      <option value="aunt">Aunt</option> 
                      <option value="nephew">Nephew</option> 
                      <option value="niece">Niece</option>  */}
                      <option value="cousins">Cousins</option> 
                      </Form.Select>               
                    </Form.Group>
                      </Col>
                    
                    <Col xs={12} md={6}>
                      <Form.Group className='mb-2' >
                        <Form.Label>Date of Birth<div>जन्म तिथि</div></Form.Label>
                        <Form.Control
                          type="date"
                          value={row.DOB}
                          onChange={(e) => handleChange(index, 'DOB', e.target.value)} placeholder="Date of Birth"
                        />                  
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                    <Form.Group className='mb-2'  >
                    <Form.Label>Education<div>शिक्षा</div></Form.Label>
                    <Form.Select onChange={(e) => handleChange(index, 'Education', e.target.value)} aria-label="Default select example">
                      <option value="">--Select--</option>
                      <option value="high_school">High School</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="under_gradiate">Under Graduate</option>
                      <option value="post_graduate">Post Graduate</option>
                      <option value="other">Other</option>
                    </Form.Select>                 
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className='mb-2'>
                      <Form.Label>Occupation <div>पेशा</div></Form.Label>
                      <Row>
                        <Col>
                          <Form.Check // prettier-ignore
                          inline
                          name={'familyocctype'+index}
                          type="radio"
                          id={'focctype-business'+index}
                          label="Business"
                          value = "business"
                          defaultChecked="true"    
                          onChange={(e) => handleChange(index, 'Familyocctype', e.target.value)}            
                          />
                          <Form.Check // prettier-ignore
                          inline
                          name={'familyocctype'+index}
                          type="radio"
                          id={'focctype-service'+index}
                          label="Service"
                          value = "service"  
                          onChange={(e) => handleChange(index, 'Familyocctype', e.target.value)}                        
                          />                     
                        </Col>
                      </Row>
                      </Form.Group>
                    </Col>
                    <Col>
                    {(row.Familyocctype == 'business') 
                      ?
                      <Form.Label>Business Information <div>व्यापार जानकारी *</div></Form.Label>
                      :
                      <Form.Label>Service Information <div>सेवा जानकारी *</div></Form.Label>
                     }
                     {
                      (row.Familyocctype == 'business')
                      ?
                      <Form.Control                   
                        type="text"
                        placeholder="About Business"
                        onChange={(e) => handleChange(index, 'Occupation', e.target.value)}
                      />
                      :
                      <Form.Select onChange={(e) => handleChange(index, 'Occupation', e.target.value)} aria-label="Default select example">
                        <option value="">--Select Service--</option>                        
                        <option value="architect">Architect</option>
                        <option value="ca">CA</option>
                        <option value="doctor">Doctor</option>
                        <option value="engineer">Engineer</option>
                        <option value="hr">HR</option>
                        <option value="it">IT</option>
                        <option value="legal">Legal</option>                        
                        <option value="other">Other</option>
                      </Form.Select>
                     }                   
                      
                      
                    </Col>

                    <Col xs={12} md={6}>
                      <Form.Group className='mb-2' >
                        <Form.Label>Mobile<div>फोन नंo</div></Form.Label>
                        <Form.Control
                        type="text"
                        value={row.Contact}
                        maxLength="10"
                        onChange={(e) => checkFamilyInput(e, index, 'Contact')}
                        // onChange={(e) => handleChange(index, 'Contact', e.target.value)}
                        placeholder="Contact"
                        />                  
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className='mb-2'>
                        <Form.Label>Blood Group<div>ब्लड ग्रुप</div></Form.Label>
                      
                        <Form.Select  onChange={(e) => handleChange(index, 'BloodGroup', e.target.value)} aria-label="Default select example">
                          <option value="">--Select--</option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                      </Form.Select>            
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={3}>
                  <div className={"row justify-content-center mt-1"}>
                  <div className='userpicblock' style={passportDivStyle}>
                      {<img src={(familypreviewSrc[index]) ? familypreviewSrc[index] : 'assets/img/upload-icon.png'}  alt="Passport" style={passportPreviewStyle} />}
                      <input type="file" class="form-control" accept="image/*" onChange={(e) => handleFamilyPic(e,index)} style={uploadInputStyle} />                          
                    </div>
                    <div className={"text-center"}>Upload Photo<div>फोटो *</div></div>
                  </div>
                </Col>
            </Row>
              <Row>
                
                
                  
                  <Col xs={12} md={4}>
                  
                  </Col>
              </Row>
              <Row>
              
              </Row> 
            
            </Card.Body>
          </Card>
          ))}
          
          </Col>
        </Row>
        
        
        <Row className='mb-5'>
        <Col style={{textAlign:"right"}}>
        <Button className='m-2 ' onClick={addRow} style={{backgroundColor:'#3a5cf6'}}>+Add Member</Button>

        </Col>
      </Row>  
        
      <Row>
        <Col style={{textAlign:"right"}}>
          <Button className='m-2 subbtn' type="submit" disabled={isButtonDisabled} style={{backgroundColor:'#f76030',borderColor:'#f76030', fontSize:'20px'}}>Save Detail</Button>
        </Col>
      </Row>
      </Form>
      </div>
      </Container>
      <Container style={{padding:'40px'}}>
        <Row>

        </Row>
      </Container>


    </>
  );
}
const passportDivStyle = {
  width: '150px',
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
};

const passportPreviewStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
};

const uploadInputStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  opacity: '0',
  cursor: 'pointer',
};

export default SurveyForm;